/*
 * @Author: huangl
 * @Date: 2022-09-13 20:10:31
 * @LastEditors: huangl
 * @LastEditTime: 2022-09-14 21:52:29
 * @Description: file content
 * @FilePath: \money-datav\vue-dataV\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'index',
    component: () => import('../views/testIndex.vue')
  },{
    path: '/info',
    name: 'Info',
    component: () => import('../views/info.vue')
  }]
const router = new VueRouter({
  routes
})

export default router